<template>
  <div>
    <div class="chat-messages" ref="scrollMessage">
      <template v-for="(group, index) in messages">
        <span class="chat-messages--date" :key="index">
          {{ group.createdAt }}
        </span>

        <template v-for="(message, index) in group.messages">
          <ChatMessage
            :readonly="model.readonly"
            :ticketId="ticketId"
            :isDemandedTab="isDemandedTab"
            :index="index"
            :key="message.id"
            :id="message.id"
            :message="message.message"
            :author="message.author"
            :type="message.type"
            :updatedAt="message.updatedAt"
            :files="message.files"
            :lastMessage="message.id === lastMessage"
          />
        </template>
      </template>
    </div>

    <div class="message-send" v-if="!model.readonly">
      <v-form ref="form" lazy-validation v-model="valid">
        <v-textarea
          hide-details
          solo
          flat
          clearable
          rows="1"
          placeholder="Digite sua mensagem"
          class="message-send--input"
          :rules="[required]"
          v-model="message"
        >
          <template v-slot:append>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-badge
                  dot
                  color="red"
                  class="badge-notify"
                  :content="files.length"
                  :value="files.length"
                >
                  <v-icon
                    id="btn-file"
                    name="btn-file"
                    v-on="on"
                    @click="sendFile"
                  >
                    attach_file
                  </v-icon>
                </v-badge>
              </template>
              <span>Clique para anexar arquivos</span>
            </v-tooltip>
          </template>
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  id="btn-send"
                  name="btn-send"
                  v-on="on"
                  @click="sendMessage"
                >
                  send
                </v-icon>
              </template>
              <span>Enviar mensagem</span>
            </v-tooltip>
          </template>
        </v-textarea>
        <input
          multiple
          ref="input"
          type="file"
          class="d-none"
          @change="handleFile($event)"
        />
      </v-form>
    </div>
  </div>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { supportMixin } from '@/mixins/support';
import { mapGetters } from 'vuex';
import { formatDate } from '@/helpers/formatting';
import { showMessage } from '@/helpers/messages';
import { SupportService } from '@/services/api/support';

export default {
  mixins: [rulesMixin, supportMixin],

  components: {
    ChatMessage: () => import('@/components/pages/supports/chat-message')
  },

  props: {
    isDemandedTab: {
      type: Boolean,
      default: false,
      require: true
    },
    ticketId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    valid: true,
    files: [],
    message: null
  }),

  computed: {
    ...mapGetters({
      messages: 'support/getMessages',
      model: 'support/getById'
    }),

    lastMessage() {
      const messages = JSON.parse(JSON.stringify(this.messages));

      const lastMessages = messages.pop();

      const senderMessages = lastMessages.messages.filter(
        x => x.type === 'sender'
      );

      const last = senderMessages.pop();

      return last ? last.id : null;
    }
  },

  created() {
    this.search();
  },

  updated() {
    this.$nextTick(() => this.scrollToBottom());
  },

  methods: {
    formatDate,

    search() {
      this.$store.commit('support/setMessages', null);

      const payload = {
        ticketId: this.ticketId,
        isDemandedTab: this.isDemandedTab
      };

      this.getFiles(payload);
      this.getMessages(payload);
    },

    async sendMessage() {
      if (!this.$refs.form.validate(true)) {
        return;
      } else {
        try {
          const supportService = new SupportService();
          const { status } = await supportService.postTicketMessage({
            ticketId: this.ticketId,
            isDemandedTab: this.isDemandedTab,
            message: this.message,
            files: this.files
          });

          if (status === 204) {
            const payload = {
              ticketId: this.ticketId,
              isDemandedTab: this.isDemandedTab
            };

            this.getMessages(payload);
            this.getFiles(payload);

            showMessage('success', 'Mensagem enviada com sucesso!');
            this.message = null;
            this.files = [];
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    sendFile() {
      this.$refs.input.value = null;
      this.$refs.input.click();
    },

    handleFile({ target }) {
      this.files = [];

      for (let i = 0; i < target.files.length; i++) {
        this.files.push(target.files[i]);
      }
    },

    scrollToBottom() {
      const container = this.$refs.scrollMessage;
      container.scrollTop = container.scrollHeight;
    }
  },

  watch: {
    messages: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$nextTick(() => this.scrollToBottom());
        }
      }
    }
  }
};
</script>
